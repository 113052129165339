import {Component, Input, OnInit} from "@angular/core";
import {ScoreItem, SimResultCreator, UserSimulationResultsDTO} from "../models/ScoreModel";
import {PhishingAttributesEnums} from "../models/PhishingAttributesEnums";
import {EmailDTO} from "../models/PhishingAttributesModel";
import {UserService} from "../services/user.service";
import {BackendService} from "../services/backend.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertPopupService} from "../services/alert.popup.service";
import { UserDataStoreService } from "../shared/service/user-data-store.service";
import { Router } from "@angular/router";
import { MainComponent } from "../main/main.component";
import {SimResultsService} from "../services/sim-results.service";

@Component({
  selector: 'app-final-score',
  templateUrl: './final-score.component.html',
  styleUrls: ['./final-score.component.scss']
})

export class FinalScoreComponent implements OnInit {
  @Input() emailAttributes!: Array<EmailDTO>;
  @Input() simulationStartTime!: Date;
  @Input() simulationEndTime!: Date;

  sender: ScoreItem = new ScoreItem();
  content: ScoreItem = new ScoreItem();
  links: ScoreItem = new ScoreItem();
  attachments: ScoreItem = new ScoreItem();
  qrCode: ScoreItem = new ScoreItem();
  detailed: ScoreItem = new ScoreItem();
  emails: ScoreItem = new ScoreItem();

  constructor(
    private userService: UserService,
    private simResultsService: SimResultsService,
    private dialog: MatDialog,
    private userDataStoreService: UserDataStoreService,
    private alertPopupService: AlertPopupService,
    private router: Router,
    private main_component: MainComponent
  ) {
  }

  ngOnInit(): void {
    if (this.emailAttributes) {
      this.emailAttributes.forEach((email: EmailDTO) => {
        this.sender.score += this.compareAnswers(email.emailPhishingAttributes?.isSenderPhishy!, !email.scores?.get(PhishingAttributesEnums.SENDER)) ? 1 : 0;
        this.sender.total += email.scores?.get(PhishingAttributesEnums.SENDER) == undefined ? 0 : 1;

        this.content.score += this.compareAnswers(email.emailPhishingAttributes?.isContentPhishy!, !email.scores?.get(PhishingAttributesEnums.CONTENT)) ? 1 : 0;
        this.content.total += email.scores?.get(PhishingAttributesEnums.CONTENT) == undefined ? 0 : 1;

        this.links.score += this.compareAnswers(email.emailPhishingAttributes?.isLinkPhishy!, !email.scores?.get(PhishingAttributesEnums.LINKS)) ? 1 : 0;
        this.links.total += email.scores?.get(PhishingAttributesEnums.LINKS) == undefined ? 0 : 1;

        this.attachments.score += this.compareAnswers(email.emailPhishingAttributes?.isAttachmentPhishy!, !email.scores?.get(PhishingAttributesEnums.ATTACHMENTS)) ? 1 : 0;
        this.attachments.total += email.scores?.get(PhishingAttributesEnums.ATTACHMENTS) == undefined ? 0 : 1;

        this.qrCode.score += this.compareAnswers(email.emailPhishingAttributes?.isQRCodePhishy!, !email.scores?.get(PhishingAttributesEnums.QR_CODE)) ? 1 : 0;
        this.qrCode.total += email.scores?.get(PhishingAttributesEnums.QR_CODE) == undefined ? 0 : 1;

        this.emails.score += this.compareAnswers(email.legit, email.user_judgment_is_legit) ? 1 : 0;
      });
      this.emails.total = this.emailAttributes.length;
      this.emails.percentage = ((this.emails.score / this.emails.total) * 100).toFixed(0) + '% ';

      this.detailed.score = this.sender.score + this.content.score + this.links.score +
        this.attachments.score + this.qrCode.score;

      this.detailed.total = this.sender.total + this.content.total + this.links.total +
        this.attachments.total + this.qrCode.total;

      this.detailed.percentage = ((this.detailed.score / this.detailed.total) * 100).toFixed(0) + '% ';

      this.submitScore();
    }
  }

  compareAnswers(answer: boolean, userAnswer: boolean | undefined): boolean {
    if(userAnswer == undefined)
      return false;

    return answer === userAnswer;
  }

  restart() {
    this.userService.handleSimulation(false);
    this.main_component.reset();
    this.router.navigate(["/home"])
  }

  onSubmitScoreBtnClick(){
    this.alertPopupService.success("Successfully submitted");
  }

  submitScore() {
    const simResultCreator: SimResultCreator = {
        userId: this.userDataStoreService.getUser().userID!,
        createDate: new Date().toISOString(),
        simulationStartTime: this.simulationStartTime.toISOString(),
        simulationCompleteTime: this.simulationEndTime.toISOString(),
        overallScore: this.emails.score,
        detailScore: this.detailed.score,
        totalEmails: this.emails.total,
        overallEmailCorrect: this.emails.score,
        totalSenderPhishy: this.sender.total,
        senderPhishyCorrect: this.sender.score,
        totalContentPhishy: this.content.total,
        contentPhishyCorrect: this.content.score,
        totalLinksPhishy: this.links.total,
        linksPhishyCorrect: this.links.score,
        totalAttachmentPhishy: this.attachments.total,
        attachmentPhishyCorrect: this.attachments.score,
        totalQRCodePhishy: this.qrCode.total,
        qrCodePhishyCorrect: this.qrCode.score,
        courseID: this.userService.getCourseID()
    };

    this.simResultsService.addSimulationResults(simResultCreator)
        .subscribe(response => {
            this.alertPopupService.success("Score submitted!", "Success")
            window.parent.postMessage('Completed', "*");
        },
        error => {
            this.alertPopupService.error("Failed to submit score. " + + error.error.details,"Fail")
        }
    );
 }
}
