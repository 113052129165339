import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject, finalize, map, of as observableOf } from "rxjs";
import { HttpServiceFacade } from "../shared/http.service.facade";
import {
    EmailClientsEnum,
    InviteUserInput,
    LoginRequest,
    RegistrationInput, Theme,
    UpdateUserInput,
    UserDTO
} from "../shared/model/user.model";
import { HttpResponse } from '@angular/common/http';
import { UserDataStoreService } from "../shared/service/user-data-store.service";
import { environment } from "src/environments/environment";
import { AlertPopupService } from "./alert.popup.service";
import {RolesEnum, UserRole} from "../shared/model/user-role.model";
import { SettingFilterInput, SettingPageEnum, SettingValueList, SettingsKeys } from "../shared/model/setting.model";
import { SettingService } from "../shared/service/setting.service";
import { WebsocketService } from "../core/websocket/websocket.service";

@Injectable({
    providedIn: 'root'
})

export class UserService {

    simulationState: Subject<boolean | null> = new Subject();
    hoveredUrl: Subject<string> = new Subject();
    darkTheme: Subject<boolean> = new Subject();
    private _isDarkTheme!: boolean;
    color = 'dark'

    allowLogout = true;

    constructor(
        private router: Router,
        private backendService: HttpServiceFacade,
        private settingService: SettingService,
        private userDataStoreService: UserDataStoreService,
        private alertPopUpService: AlertPopupService,
        private webSocketService: WebsocketService
    ) {
        this.darkTheme.subscribe(value => {
            this.updateTheme(value);

            if(environment.loadDataFromBE && this.userDataStoreService.getUser()) {
                let input: SettingValueList = {
                    userId: this.userDataStoreService.getUser().userID,
                    page: SettingPageEnum.USER,
                    settingValueList: [{
                        settingKey: SettingsKeys.DARK_MODE,
                        value: (value) ? "true" : "false"
                    }]
                }
                this.settingService.saveSettings(input)
                    .subscribe({
                            next: (res) => {
                            },
                            error: () => {
                                this.alertPopUpService.error("Failed to save theme settings");
                            }
                        }
                    )
            }
        })

    }

    private updateTheme(isDark: boolean) {
        this._isDarkTheme = isDark
        this.savePrefTheme(isDark ? Theme.DARK : Theme.LIGHT)

        if (isDark)
            this.color = 'light'
        else
            this.color = 'dark'
    }

    login(credentials: LoginRequest): Observable<UserDTO> {

        if (!environment.loadDataFromBE) {
            const user: UserDTO = {
                email: credentials.email,
                emailClient: EmailClientsEnum.OUTLOOK,
                firstName: 'First Name',
                lastName: 'Last Name',
                roleName: 'Admin',
                roleID: RolesEnum.ADMIN,
                lastLoginTime: ""
            }
            this.userDataStoreService.loadUser(user);
            return observableOf(user);
        } else {
            const input: any = {
                login: credentials.email,
                password: credentials.password
            };
            return this.backendService
                .postResponse<UserDTO>(
                    '/api/users/login',
                    input
                )
                .pipe(
                    map((response: HttpResponse<UserDTO>) => {
                        if (response.headers.has('Authorization')) {
                            let user = response.body!;
                            user.token = response.headers.get('Authorization')!;
                            this.userDataStoreService.loadUser(user);
                            this.getPrefTheme();
                            this.webSocketService.initializeWebSocketConnection(environment.backendWebsocketURL, []);
                            return user;
                        } else throw new Error('login failed');
                    })
                );
        }
    }


    getRoles(): Observable<UserRole[]> {
        return this.backendService.get<UserRole[]>('/api/users/roles');
    }

    register(registrationInput: RegistrationInput): Observable<any> {
        return this.backendService.post<any>('/api/users/register', registrationInput);
    }

    loginWithAuthToken(authToken: string): Observable<UserDTO> {
        return this.backendService
            .postResponse<UserDTO>(
                '/api/users/login/authToken',
                authToken
            )
            .pipe(
                map((response: HttpResponse<UserDTO>) => {
                    if (response.headers.has('Authorization')) {
                        let user = response.body!;
                        user.token = response.headers.get('Authorization')!;
                        user.isSSO = true;
                        this.userDataStoreService.loadUser(user);
                        this.getPrefTheme();
                        // this.loadThemeSettings(user)
                        return user;
                    } else throw new Error('login failed');
                })
            );
    }

    inviteUser(inviteUser: InviteUserInput): Observable<UserDTO>{
        return this.backendService.post('/api/users/invite', inviteUser);
    }

    getUser(userId: number): Observable<UserDTO>{
        return this.backendService.get<UserDTO>(`/api/users/${userId}`);
    }

    updateUser(updatedUser: UpdateUserInput): Observable<UserDTO>{
        return this.backendService.put<UserDTO>(`/api/users/`, updatedUser);
    }

    savePrefTheme(pref: string) {
        window.localStorage.setItem("theme", pref)
    }

    getPrefTheme() {
        return window.localStorage.getItem("theme")
    }

    saveCourseID(courseID: string) {
        window.localStorage.setItem("CourseID", courseID)
    }

    getCourseID() {
        return window.localStorage.getItem("CourseID")
    }

    handleSimulation(state: boolean | null) {
        this.simulationState.next(state);
    }

    getThemeColor() {
        return this.color
    }

    get isDarkTheme(): boolean {
        return this._isDarkTheme;
    }

    logout() {
        if (!environment.loadDataFromBE) {
            this.logoutActions()
        } else {
            this.backendService
                .post('/api/users/logout')
                .pipe(finalize(() => {
                    this.logoutActions()
                }))
                .subscribe(() => {
                });
        }
    }

    logoutWithAuthToken(authToken: string) {
        if (!environment.loadDataFromBE) {
            this.logoutActions()
        } else {
            this.backendService
                .post('/api/users/logout/authToken', authToken)
                .pipe(finalize(() => {
                    this.logoutActions()
                }))
                .subscribe(() => {
                });
        }
    }

    logoutActions() {
        this.webSocketService.disconnect();
        window.localStorage.removeItem("loggedInUser")
        window.localStorage.removeItem("CourseID")
        window.localStorage.clear()
        this.handleSimulation(false)
        this.router.navigate(["/login"])
    }

    setAllowLogout(allowLogout: boolean) {
        this.allowLogout = allowLogout;
    }

    getAllowLogout() {
        return this.allowLogout;
    }

}
